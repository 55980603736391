var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignup = {
    templateContainer: $(),

    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }

      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
        var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
        var $emailError = $('.js-email-signup__error', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $locInput = $('input[name="location"]', $emailContainerNode);
        var $redirectElement = $('.js-email-signup-path', $emailContainerNode);
        var $emailSkip = $('.js-mantle-cta-button', $emailContainerNode);
        var location = $locInput.val();

        $emailForm.off('submit.form').on('submit.form', function (submitEvt) {
          submitEvt.preventDefault();
          var destinationPath;
          var emailValue;

          if (Drupal.settings.elc_common.elc_site_type.coresite) {
            destinationPath = $redirectElement.length ? $redirectElement.val() : '';
            emailValue = $emailInput.val();
            if (emailValue !== '' && destinationPath !== '') {
              destinationPath = destinationPath + '?email=' + encodeURIComponent(emailValue);
              window.location.href = destinationPath;
            } else {
              if (emailValue === '') {
                $emailError.removeClass('hidden');
              }
            }

            return false;
          }
          $emailSuccess.add($emailError).addClass('hidden');
          $emailForm.find('.error').removeClass('error');
          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // microservice_api
          if (!Drupal.settings.use_jsonrpc) {
            generic.data.remove('UserId', 'session');
          }

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            apiFilterName: 'email-signup',
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var data = jsonRpcResponse.getData();

              if (Drupal.settings.use_jsonrpc) {
                var alreadySignedUp = data.userinfo.previously_opted_in_email_promotion;
                var alreadyPurchased = data.userinfo.previously_purchased;

                if (alreadySignedUp || alreadyPurchased) {
                  $(document).trigger('email_signup:success_but_signed_up');
                } else {
                  $(document).trigger('email_signup:success_and_new', location);
                }
              } else {
              // microservice_api
                if (!!data.uuid) {
                  generic.data.set('UserId', data.uuid, { 'type': 'session' });

                  if ('initialOptIn' in data) {
                    $(document).trigger('email_signup:success_but_signed_up');
                  } else {
                    $(document).trigger('email_signup:success_and_new', location);
                  }
                }
              }
            },
            // overrides for the default error handler
            statusCode: {
            // 400: function(xhr) { }
            },
            onFailure: function (jsonRpcResponse) {
              var messages = jsonRpcResponse.getMessages();

              if (Drupal.settings.use_jsonrpc) {
                $emailError.empty().removeClass('hidden');
                $emailContainerNode.find('.error').removeClass('error');
                $.each(messages, function (index, value) {
                  var errorInputfield = messages[index].tags[2].split('field.')[1];

                  $('[name="' + errorInputfield.toUpperCase() + '"]', $emailContainerNode).addClass('error').next('.selectBox').addClass('error');
                  $emailError.append('<span>' + messages[index].text + '</span>');
                });
              } else {
              // last-chance fallthrough for REST errors
              // Most error conditions will trigger "statusCode" handlers
                $.colorbox({
                  html: 'Network error'
                });
              }
              generic.focusErrors($('.js-email-signup__error'), $('.js-email-signup__form'));
            }
          });
        });

        if ($emailSkip.length > 0) {
          $(document).on('click', '#cboxClose, #cboxOverlay', function () {
            $emailSkip.trigger('click');
          });
        }
      });
    },

    gdprRemInfoMessageBox: function () {
      if ($('.info-msg').get(0)) {
        $('.info-msg').remove();
      }
    },

    gdprSetInfoBoxPosition: function (infoLink, infoMessageBox, infoMsgArrow, isMobile) {
      var infoLinkTop = infoLink.offset().top + infoLink.height() + 10;
      var infoLinkLeft = infoLink.offset().left;
      var infoMsgMaxWidth = parseInt(infoMessageBox.css('max-width').replace('px', ''));

      if (isMobile) {
        infoMsgArrow.css({
          left: (infoLinkLeft - 10) + 'px'
        });
        infoLinkLeft = 10;
      } else if ((infoMessageBox.outerWidth(true) === infoMsgMaxWidth) && ((infoLinkLeft + (infoMsgMaxWidth + 25)) > screen.width)) {
        infoLinkLeft = infoLinkLeft - 480;
        infoMsgArrow.addClass('top-right-arrow');
      }
      infoMessageBox.css({
        top: infoLinkTop + 'px',
        left: infoLinkLeft + 'px'
      });
    },

    gdprToolTip: function (context) {
      if (Drupal.settings.gdpr_compliant) {
        var isMobile = Unison.fetch.now().name === 'small';

        $('.info-link', context).live('click', function (e) {
          e.preventDefault();
          var infoLink = $(e.currentTarget);

          site.emailSignup.gdprRemInfoMessageBox();

          $("<div class='info-msg'><div class='info-msg__arrow'></div><div class='info-msg__txt'></div></div>").appendTo('body');
          var msgInEl = infoLink.attr('message-in');
          var infoBoxId = $('#' + msgInEl);
          var infoMessageBox = $('.info-msg');
          var infoMsgArrow = $('.info-msg__arrow');

          $('.info-msg__txt').html("<div class='info-msg__close'>X</div>" + infoBoxId.html());

          $('#cboxClose, .info-msg__close').live('mousedown', function () {
            site.emailSignup.gdprRemInfoMessageBox();
          });
          site.emailSignup.gdprSetInfoBoxPosition(infoLink, infoMessageBox, infoMsgArrow, isMobile);
          $(window).on('resize-debounced', site.emailSignup.gdprSetInfoBoxPosition);
          $(infoMessageBox).live('mouseover', function () {
            if (msgInEl === 'popreg-terms-msg') {
              $(infoBoxId).closest('li').addClass('active');
            }
          });
        });
        $('.info-msg__txt span').live('mousedown', function () {
          window.location = $(this).attr('data-url');
        });
      }
    }
  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context, settings) {
      site.emailSignup.templateContainer = $('.js-email-signup', context);
      site.emailSignup.initEmailSignup();
      site.emailSignup.gdprToolTip(context);
    }
  };
})(jQuery);
